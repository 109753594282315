import React from 'react';
import Image from '@atoms/Image';

const Presentation = React.forwardRef(({ currentRosFeature, cache = {} }, ref) => {
  const getUrl = () => {
    let url;
    if (cache[currentRosFeature.question_number]) {
      url = cache[currentRosFeature.question_number];
    } else {
      url = process.env.NEXT_PUBLIC_BUCKET_URL + currentRosFeature.presentation_slide_s3_key;
    }
    return url;
  };

  return (
    <>
      <div ref={ref} className={'flex justify-center w-full h-full absolute left-0 bottom-0'}>
        <div className={'flex justify-center w-full h-full'}>
          <Image src={getUrl()} className='object-contain w-auto h-auto' />
        </div>
      </div>
    </>
  );
});

export default Presentation;
