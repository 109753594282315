const defaultUsers = [{ id: 1, body: 'Deepak' }, { id: 2, body: 'Tom' }, { id: 3, body: 'Mina' }, { id: 4, body: 'Daniel' }, { id: 5, body: 'Ryan' }, { id: 6, body: 'Emily' }, { id: 7, body: 'Helen' }, { id: 8, body: 'Will' }];

export const wheelPreviewCheck = ({ setOpts, setNoUser, setWheelState, opts }) => {
  if (opts?.length < 8) {
    setOpts((prev) => [...prev, ...defaultUsers.slice(0, 8 - prev?.length)]);
  }

  setNoUser(false);
  setWheelState('spin');
};
