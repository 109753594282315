export const getUserNameOfCommentor = async ({ isRos, hiddenData, rosId, showUser, getCommentsDetails, streamId, setOpts, setNewWheelUser, setInteractionState, setNoUser, setWheelState, turncateWinningWheelOpt }) => {
  if (isRos) {
    const hiddenIds = hiddenData[rosId] || [];
    const wheelUser = showUser;
    const getCommentDetails = await getCommentsDetails(streamId, wheelUser);
    if (getCommentDetails.status) {
      if (getCommentDetails.entity.length) {
        const filteredUsers = getCommentDetails.entity.filter(user => !hiddenIds.includes(user.id));
        setOpts(filteredUsers.map(user => ({ id: user.id, body: turncateWinningWheelOpt(user.body) })));
        setNewWheelUser(filteredUsers);
        setNoUser(false);
        setInteractionState('running');
      } else {
        setInteractionState('no data');
        setNoUser(true);
        setOpts([]);
        setWheelState('empty');
      }
    }
  }
};
