export const clear = (canvasRef) => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
};

export const rotate = (canvasRef, angle, createWheel) => {
  if (!canvasRef?.current || !angle) return;

  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');

  clear(canvasRef);
  ctx.save();
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(angle.current);
  ctx.translate(-canvas.width / 2, -canvas.height / 2);
  createWheel();
  ctx.restore();
};
