import {
  useContext, useEffect, forwardRef, useRef, useState, useImperativeHandle
} from 'react';

import { StreamDataContext } from '@components/context/StreamContext';

import {
  updateEngagement, getCommentsDetails,
} from '@services/youtube-platform.service';
import { reArrangeOptions } from '@services/utils.service';

import WheelCanvas from './WheelCanvas';
import ConfettiItems from './ConfettiItems';
import WheelCanvasResult from './WheelCanvasResult';
import { getFeatureComments } from './wheel-canvas.service';
import { handleEngagement } from '@services/interactions-service';
import { wheelPreviewCheck } from './helpers/wheelPreviewCheck';
import { getUserNameOfCommentor } from './helpers/getUserNameOfCommenters';

const WinnerWheel = forwardRef(({
  meta,
  splitDiv,
  streamId,
  layoutMode,
  stopFeature,
  currentIndex,
  platformType,
  setShowToast,
  isFullScreen,
  updateFSMeta,
  setIsStarted,
  setShowRosModel,
  interactionType,
  currentRosResult,
  interactionState,
  startInteraction,
  currentRosFeature,
  moderationModeRef,
  setInteractionState,
  setStartInteraction,
  showInstantCustomisationPopup = false
}, ref) => {
  const [result, setResult] = useState([]);
  const [winner, setWinner] = useState(null);
  const [noUser, setNoUser] = useState(false);
  const [wheelId, setWheelId] = useState(null);
  const [fsWinnerId, setFsWinnerId] = useState();
  const [winnerInfo, setWinnerInfo] = useState(null);
  const [newWheelUser, setNewWheelUser] = useState([]);
  const [isExploding, setIsExploding] = useState(false);
  const [wheelState, setWheelState] = useState(false);
  const [isResultView, setIsResultView] = useState(false);
  const [isPreview, setIsPreview] = useState(window?.location?.href?.includes('preview'));
  const [showConfetti, setShowConfetti] = useState(currentRosFeature?.setting?.enableConfetti || true);

  const [opts, _setOpts] = useState(currentRosFeature.options || []);

  const setOpts = (opts = []) => {
    _setOpts(opts);
  };

  let wheelIdRef = useRef(wheelId);
  const { isRos } = interactionType;
  const { setFeatureId, hiddenData, setHiddenData } = useContext(StreamDataContext);

  const setInteraction = (engagementId) => {
    setShowRosModel(false);
    setWheelId(engagementId);
    setFeatureId(engagementId);
    wheelIdRef.current = engagementId;
  };

  const handleWheel = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });
    if (response.status && response.entity) {
      const interactionDetails = {
        type: currentRosFeature.type,
        colors: currentRosFeature.color,
        id: response.entity.engagementId,
        settings: currentRosFeature.setting,
      };
      setInteraction(response.entity.engagementId);
      updateFSMeta({ activeInteraction: interactionDetails });
    }
  };

  const showResult = (options) => {
    if (options) {
      const resultArr = reArrangeOptions(options);
      setIsResultView(true);
      setResult(resultArr.map(res => ({ ...res, body: res.data })));
    }
  };

  const turncateWinningWheelOpt = (text) => {
    if (text && text.length > 22) {
      return `${text.slice(0, 22)}...`;
    }
    return text;
  };

  const getUserNameOfCommentorProps = {
    isRos,
    setOpts,
    streamId,
    setNoUser,
    hiddenData,
    setWheelState,
    setNewWheelUser,
    getCommentsDetails,
    setInteractionState,
    turncateWinningWheelOpt,
    rosId: currentRosFeature.id,
    showUser: currentRosFeature?.setting?.wheelShowUser,
  };

  const getUserNameOfFeatureComments = () => {
    getFeatureComments({
      setOpts,
      streamId,
      setNoUser,
      hiddenData,
      setWheelState,
      setNewWheelUser,
      setInteractionState,
      turncateWinningWheelOpt,
      question: currentRosFeature,
    });
  };

  const startWheel = () => {
    if (process.env.NEXT_PUBLIC_APP_ENV === 'sandbox') {
      setWheelState('spin');
    }
    setIsExploding(false);
    setIsResultView(false);
  };

  useEffect(() => {
    if (currentRosFeature.type === 'wheel' && (!currentRosFeature.options || currentRosFeature.options?.length < 2)) {
      currentRosFeature.options = [{ body: 'Option 1' }, { body: 'Option 2' }];
    }
    if (!isRos) {
      showResult(currentRosFeature.options);
    } else if (currentRosFeature.type !== 'newWheel' && currentRosFeature.options?.length > 0) {
      setOpts(currentRosFeature.options);
    }
  }, [currentRosFeature.options]);

  useEffect(() => {
    const winner = currentRosResult[0]?.options?.filter(option => option.isWinner).map(option => ({ ...option, value: option?.data }));

    if (winner?.length > 0) {
      setWinnerInfo({ ...winner[0] });
      setIsResultView(true);
    }
    if (!winner || currentRosResult?.length === 0) {
      setWinnerInfo(null);
    }

    if (!isRos) return;
    if (currentRosResult?.length) {
      const prevResult = currentRosResult[0];
      if (prevResult.type !== 'newWheel') {
        showResult(prevResult.options);
      }
    } else if (currentRosFeature.type === 'newWheel' && currentRosFeature.options?.length > 0) {
      setOpts(currentRosFeature.options);
    }
  }, [currentRosResult]);

  useEffect(() => {
    (async () => {
      if (!winner || !wheelId) return;

      const response = await updateEngagement(wheelId, currentRosFeature.type, { winner, type: 'winner', streamId });

      if (!response.status) return;

      setWinner(null);
      setWheelId(null);
    })();
  }, [wheelId, winner]);

  useEffect(() => {
    if (splitDiv) {
      setIsResultView(false);
    }
  }, [splitDiv]);

  useEffect(() => {
    const hiddenIds = hiddenData[currentRosFeature.id] || [];
    if (currentRosFeature.options?.length === 0) return;
    const filterOptions = currentRosFeature.options?.filter(o => !hiddenIds.includes(o.id));
    setOpts(filterOptions);
  }, [hiddenData]);

  useEffect(() => {
    if (currentRosFeature.type === 'newWheel') {
      if (currentRosFeature.metadata?.featureId && currentRosFeature.metadata.featureId !== streamId) {
        getUserNameOfFeatureComments();
      } else {
        if (isPreview) return;
        getUserNameOfCommentor(getUserNameOfCommentorProps);
      }
    }
  }, [currentRosFeature.id, hiddenData]);

  useEffect(() => {
    if (!currentRosFeature.id) return;
    setShowRosModel(false);
    if (wheelState === 'empty') return;
    if (moderationModeRef.current) return;

    if (startInteraction || interactionState === 'start') {
      startWheel();
      if (interactionState === 'start') {
        setWheelState('spin');
        setIsExploding(false);
      }
    }
  }, [currentRosFeature.id, startInteraction, interactionState]);

  useEffect(() => {
    if (meta?.wheel?.winnerId !== undefined) {
      setFsWinnerId(() => meta.wheel.winnerId);
      if (meta?.wheel?.wheelId) {
        setInteraction({ engagementId: meta.wheel.wheelId });
      }
      startWheel();
    }
  }, [meta]);

  useImperativeHandle(ref, () => ({
    newWheelUser
  }));

  const removeParticipant = () => {
    const hiddenIds = hiddenData[currentRosFeature.id] || [];
    hiddenIds.push(winnerInfo.id);
    setHiddenData({ ...hiddenData, [currentRosFeature.id]: hiddenIds });
    setInteractionState('running');
    setStartInteraction(true);
    setIsStarted(true);
    setWheelState(false);
    setWinnerInfo(null);
  };

  useEffect(() => {
    setShowConfetti(currentRosFeature?.setting?.enableConfetti || true);
    if (currentRosFeature?.type !== 'newWheel' && currentRosFeature?.type !== 'wheel') {
      setWheelState('spin');
      return;
    }
    if (!isPreview) return;
    if (currentRosFeature?.type === 'wheel') { setWheelState('spin'); return; }
    wheelPreviewCheck({ setOpts, setNoUser, setWheelState, opts });
  }, [isPreview, opts, currentRosFeature?.id]);

  useEffect(() => {
    setIsPreview(window?.location?.href?.includes('preview') || window?.location?.href?.includes('sandbox'));
    if (wheelState === 'spin') {
      setWinnerInfo(null);
      handleWheel({ interactionId: null });
    }
    if (wheelState !== 'empty') return;
    const intervalId = setInterval(() => getUserNameOfCommentor(getUserNameOfCommentorProps), 2000);
    return () => clearInterval(intervalId);
  }, [wheelState]);

  return (
    <>
      <div id='choice-circle-div' className={`w-full h-full ${interactionState === 'ready' ? 'filter blur-lg' : ''}`}>
        {showConfetti && isExploding && opts?.length > 0 && (
          <ConfettiItems
            isExploding={isExploding}
            setIsExploding={setIsExploding}
            setShowToast={setShowToast}
          />
        )}
        <div className='relative h-full rounded-lg overflow-hidden'>
          <div className={'h-full flex justify-center items-center'}>
            {isResultView ? (
              <WheelCanvasResult opts={result} />
            ) : (
              <WheelCanvas
                opts={opts}
                winner={winner}
                noUser={noUser}
                key={opts?.length}
                splitDiv={splitDiv}
                isPreview={isPreview}
                setWinner={setWinner}
                layoutMode={layoutMode}
                wheelState={wheelState}
                winnerInfo={winnerInfo}
                fsWinnerId={fsWinnerId}
                isExploding={isExploding}
                stopFeature={stopFeature}
                isFullScreen={isFullScreen}
                currentIndex={currentIndex}
                updateFSMeta={updateFSMeta}
                setIsStarted={setIsStarted}
                showConfetti={showConfetti}
                setWinnerInfo={setWinnerInfo}
                setFsWinnerId={setFsWinnerId}
                setWheelState={setWheelState}
                setIsExploding={setIsExploding}
                currentRosResult={currentRosResult}
                removeParticipant={removeParticipant}
                currentRosFeature={currentRosFeature}
                setInteractionState={setInteractionState}
                setStartInteraction={setStartInteraction}
                showInstantCustomisationPopup={showInstantCustomisationPopup}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default WinnerWheel;
