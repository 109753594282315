import { logToCloudWatch } from '@services/logger.service';

import { useState, useRef, useEffect, useCallback, type MutableRefObject } from 'react';
import db from '@services/firebase-service';
import { doc, onSnapshot } from 'firebase/firestore';
import { stopEngagement, getFeatureByQId } from '@services/youtube-platform.service';
import { handleEngagement } from '@services/interactions-service';
import TileManager from '../helper';

export const useRemoveOldTilesState = () => {
  const SAVE_TILES_STATE_TIME_WINDOW = 1000 * 60 * 60 * 24 * 2; // 2 days
  const localKeys = Object.keys(localStorage);
  const talkingTilesKeys = localKeys.filter((key) => key.startsWith('talking-tiles-state'));

  talkingTilesKeys.forEach((key) => {
    const localData = localStorage.getItem(key);
    if (!localData) {
      return;
    }
    const data = JSON.parse(localData);
    if (data?.timestamp < Date.now() - SAVE_TILES_STATE_TIME_WINDOW) {
      localStorage.removeItem(key);
    }
  });
};

interface Comment {
  words: string[];
  [key: string]: any;
}

interface RosFeature {
  id: string;
  setting: any;
  color: {
    talkingTilesBgColor?: string;
    talkingTilesTextColor?: string;
    multipleColors?: boolean;
    [key: string]: any;
  };
  comments?: Comment[];
  [key: string]: any;
}

interface RosResult {
  id: string;
  comments: Comment[];
  [key: string]: any;
}

interface InteractionDetails {
  id: string;
  settings: any;
  colors: any;
  [key: string]: any;
}

interface FirestoreUnsub {
  unsub?: () => void;
}

export const useTalkingTilesSetup = (
  currentRosFeature: RosFeature,
  currentRosResult: RosResult[],
  setCurrentFeatureId: (id: string) => void,
  setFeatureId: (id: string) => void
) => {
  const [ttId, setttId] = useState<string>('');
  const resultData = useRef<Comment[]>([]);
  const comments = useRef<Comment[]>([]);

  const setupTalkingTiles = useCallback(({ id }: { id: string }) => {
    comments.current = [];
    setCurrentFeatureId(id);
    setttId(id);
    setFeatureId(id);
    if (!(currentRosResult.length > 0)) {
      resultData.current = [];
      return;
    }

    resultData.current = currentRosResult[0].comments;
  }, [currentRosFeature, currentRosResult, setCurrentFeatureId, setFeatureId]);

  return {
    ttId,
    setttId,
    resultData,
    comments,
    setupTalkingTiles
  };
};

export const useEngagementHandling = (
  streamId: string,
  platformType: string,
  currentRosFeature: RosFeature,
  setupTalkingTiles: (details: { id: string }) => void,
  updateFSMeta: (data: { activeInteraction: any }) => void,
  firestoreUnsub: FirestoreUnsub,
  ttId: string,
  saveTilesLocalStorage: () => void,
  resultData?: MutableRefObject<Comment[]>
) => {
  const [isResultTileLoading, setIsResultTileLoading] = useState<boolean>(false);

  const handleTalkingTiles = useCallback(async ({ interactionId = null }: { interactionId: null | undefined }) => {
    const response = await handleEngagement({
      streamId,
      platformType,
      currentRosFeature,
      interactionId
    });
    if (!(response.status && response.entity)) return;

    const interactionDetails: InteractionDetails = {
      id: response.entity.engagementId,
      settings: currentRosFeature.setting,
      colors: currentRosFeature.color
    };

    setupTalkingTiles(interactionDetails);

    updateFSMeta({ activeInteraction: { ...interactionDetails, type: 'talkingTiles' } });
  }, [streamId, platformType, currentRosFeature.id, setupTalkingTiles, updateFSMeta,]);

  const unsubscribeFromFirestore = useCallback(() => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  }, [firestoreUnsub]);

  const stopTalkingTiles = useCallback(async () => {
    saveTilesLocalStorage();
    unsubscribeFromFirestore();
    const sessionId = localStorage.getItem('sessionId');
    await stopEngagement(streamId, 'talkingTiles', { engagementId: ttId, platformType, sessionId });
    setIsResultTileLoading(true);

    const response = await getFeatureByQId(currentRosFeature.id, streamId, 'talkingTiles');
    if (!(response.status && response.entity && resultData?.current)) return;
    resultData.current = response.entity[0].comments;
    setIsResultTileLoading(false);
  }, [
    saveTilesLocalStorage,
    unsubscribeFromFirestore,
    streamId,
    ttId,
    platformType,
    currentRosFeature.id,
    resultData
  ]);

  return {
    isResultTileLoading,
    handleTalkingTiles,
    unsubscribeFromFirestore,
    stopTalkingTiles
  };
};

export const useTileManager = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
  currentRosFeature: RosFeature,
  startInteraction: boolean,
  setTiles: (tiles: any[]) => void,
  ttId: string,

) => {
  const managerRef = useRef<TileManager | null>(null);
  const processedCommentsCount = useRef<number>(0);

  const initializeManager = useCallback(() => {
    if (!containerRef.current) return;
    managerRef.current = new TileManager(
      containerRef.current,
      currentRosFeature,
      currentRosFeature?.color?.talkingTilesBgColor,
      currentRosFeature?.color?.multipleColors ? '#111111' : currentRosFeature.color?.talkingTilesTextColor,
    );

    managerRef.current.setCurrentRosFeature(currentRosFeature);
    managerRef.current.setOnTilesUpdated(setTiles);
  }, [currentRosFeature, containerRef, setTiles, ttId]);

  useEffect(() => {
    if (managerRef.current) {
      managerRef.current.destroy();
    }
    initializeManager();

    if (managerRef.current && containerRef.current) {
      managerRef.current.setContainer(containerRef.current);
      managerRef.current.setContainerHeight(containerRef.current.getBoundingClientRect().height);
    }
  }, [containerRef.current, startInteraction, ttId]);

  return {
    managerRef,
    processedCommentsCount,
    initializeManager
  };
};

export const useFirestoreSubscription = (
  ttId: string,
  isRos: boolean,
  streamId: string,
  setFirestoreUnsub: (unsub: FirestoreUnsub) => void,
  managerRef: MutableRefObject<TileManager | null>,
  processedCommentsCount: MutableRefObject<number>,
  setShowNotification: (show: boolean) => void,
  comments?: MutableRefObject<Comment[]>,
  startInteraction?: boolean
) => {
  useEffect(() => {
    if (!(ttId)) return;

    const logData = {
      streamId,
      interactionType: 'talkingTiles',
      interactionId: ttId
    };

    const unsubscribe = onSnapshot(doc(db, 'streams', streamId, 'talkingTiles', ttId), (document) => {
      logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
      if (!(document.exists() && managerRef.current)) return;
      let { commentList } = document.data();
      const newComments = commentList.filter((comment: Comment) => comment?.words?.length > 0);
      if (newComments.length > 0) setShowNotification(false);

      const commentsToAdd = newComments.filter((comment: Comment) => comment.words);

      if (commentsToAdd.length > 0) {
        managerRef.current.dropTile({
          comments: commentsToAdd,
          numCols: managerRef.current.getNumCols(),
        });
        processedCommentsCount.current += commentsToAdd.length;
      } else {
        console.log('No new comments to add');
      }

      if (comments) {
        comments.current = newComments;
      }
    }, (error) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'));

    setFirestoreUnsub({
      unsub: unsubscribe
    });

    return () => {
      unsubscribe();
    };
  }, [ttId, isRos, streamId, setFirestoreUnsub, managerRef, processedCommentsCount, setShowNotification, comments, startInteraction]);
};

export const useSaveTilesState = (
  managerRef: MutableRefObject<TileManager | null>,
  ttId: string,
  startInteraction: boolean
) => {
  const saveTilesLocalStorage = useCallback(() => {
    if (!(managerRef.current && ttId)) return;
    const tilesState = managerRef.current.getTilesState();

    if (tilesState.length > 0) {
      const stateToSave = {
        comments: tilesState,
        timestamp: Date.now()
      };
      localStorage.setItem(`tilesState_${ttId}`, JSON.stringify(stateToSave));
    }
  }, [ttId, startInteraction]);

  return { saveTilesLocalStorage };
};

export const useRestoreTilesState = (
  managerRef: MutableRefObject<TileManager | null>,
  ttId: string,
  startInteraction: boolean,
  currentRosResult: RosResult[]
) => {
  const restoreTilesState = useCallback(() => {
    if (!(ttId && managerRef.current)) return;

    const savedStateString = localStorage.getItem(`tilesState_${ttId}`);

    if (!savedStateString) return;

    try {
      const savedState = JSON.parse(savedStateString);
      const currentTime = Date.now();
      const SAVE_TILES_STATE_TIME_WINDOW = 2 * 1000 * 24 * 60 * 60; // 2 days

      if (savedState.timestamp &&
          (currentTime - savedState.timestamp) < SAVE_TILES_STATE_TIME_WINDOW &&
          savedState.comments &&
          savedState.comments.length > 0) {
        managerRef.current.restoreTilesState(savedState.comments);
      }
    } catch (error) {
      console.error('Error restoring tiles state:', error);
    }
  }, [ttId, managerRef]);

  useEffect(() => {
    if (startInteraction && ttId) {
      restoreTilesState();
    }
  }, [startInteraction, ttId]);

  return { restoreTilesState };
};
