import { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
import DeleteIcon from '@mui/icons-material/Delete';

import Image from '@atoms/Image';
import HoverBox from '@atoms/HoverBox';

import Button from '@atoms/new/button';
import { rotate } from './helperfunctions';
import { drawWheel, colors } from './wheel-canvas.service';
import { chromaPaletteParser } from '../helper/chromaPaletteParser';

const TOTAL_NUMBER_OF_ROTATIONS = 5;
const WheelCanvas = ({
  opts, wheelState, setWheelState, setWinner, stopFeature, splitDiv,
  isFullScreen, setIsExploding, setWinnerInfo, winnerInfo, fsWinnerId,
  updateFSMeta, setFsWinnerId,
  removeParticipant, currentRosFeature,
  setInteractionState,
  setStartInteraction,
  setIsStarted,
  layoutMode,
  noUser,
  isPreview,
  currentRosResult,
  isExploding,
  showInstantCustomisationPopup = false,
  showConfetti = true
}) => {
  const pi = Math.PI;
  const oneFullRotation = 2 * pi;
  const sectorAngle = (2 * pi) / opts.length;
  const radiusOffset = layoutMode === 'square' ? 210 : 90;

  const angle = useRef(0);
  const winnerIndex = useRef();
  const totalAngle = useRef(0);
  const canvasRef = useRef(null);

  const [radius, setRadius] = useState();
  const [animate, setanimate] = useState({});
  const [canvasSide, setCanvasSide] = useState();

  let chromaColor;
  if (currentRosFeature.type === 'wheel') chromaColor = currentRosFeature?.color?.choiceCircleBgColor;
  else if (currentRosFeature.type === 'newWheel') chromaColor = currentRosFeature?.color?.winningWheelBgColor;

  const newColors = chromaPaletteParser().parse(colors, chromaColor);
  const textColor = currentRosFeature?.color?.winningWheelHeadingColor;
  const [wheelCreated, setWheelCreated] = useState(false);

  const createWheel = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const isResult = false;
    drawWheel(canvas, radius, opts, canvasSide, isResult, chromaColor, isPreview);
    setWheelCreated(true);
  };

  useEffect(() => {
    const hero = document.getElementById('hero');
    const marginY = 90;
    setRadius(parseInt((hero.clientHeight - radiusOffset - marginY) / 2, 10));
    setCanvasSide((hero.clientHeight - radiusOffset - marginY + 20));
  }, [radiusOffset, showInstantCustomisationPopup]);

  const getWinnerAngle = (index) => {
    const i = opts.length * 2 - index;
    return (i * sectorAngle) - (sectorAngle / 2);
  };

  const onComplete = () => {
    if (wheelState === 'empty') return;
    setWheelState('ready');
    setInteractionState('stopped');
    stopFeature();
    if (showConfetti) { setIsExploding(true); }
    setFsWinnerId();
    if (isPreview) return;
    setIsStarted(false);
    setStartInteraction(false);
  };

  const getRandomArbitrary = (min, max) => Math.random() * (max - min) + min;

  const initiateSpin = () => {
    angle.current = 0;
    totalAngle.current = 0;

    setanimate({});

    const winnerAngleInRadians = TOTAL_NUMBER_OF_ROTATIONS
    * oneFullRotation
    + getWinnerAngle(winnerIndex.current);
    const winnerAngleInDegrees = ((winnerAngleInRadians * 180) / pi);
    const randomOffset = getRandomArbitrary(0.05, 0.1);
    setanimate({
      rotate: [0, winnerAngleInDegrees + randomOffset],
      transition: {
        duration: 7, ease: [0.25, 0.1, 0.25, 1.0] // Bezier curve points
      }
    });
  };

  const getWinnerFromLot = () => {
    if (opts.length) {
      let randomIndex;
      if (fsWinnerId) {
        randomIndex = opts.findIndex((opt) => opt.id === fsWinnerId);
      } else {
        randomIndex = Math.floor(Math.random() * opts.length);
      }

      const winnerId = opts[randomIndex]?.id;
      const winnerResult = opts.map((option, i) => ({
        ...option,
        data: option.body,
        isWinner: option.id === winnerId,
        color: newColors[i % newColors.length]
      }));
      setWinner(winnerResult);
      winnerIndex.current = randomIndex;

      // Update FS for other subscribers so they can see the same winner
      updateFSMeta({
        wheel: {
          winnerIndex: randomIndex,
          timestamp: Date.now(),
          winnerId
        }
      });
    }
  };

  function spinToWinner() {
    if (currentRosResult[0]?.options?.length !== opts?.length) return;

    const winner = currentRosResult[0]?.options?.filter(option => option.isWinner).map(option => ({ ...option, value: option?.data }));

    const index = opts?.findIndex((sr) => sr.body === winner?.[0]?.value);
    const i = opts.length * 2 - index;
    angle.current = i * sectorAngle - sectorAngle / 2;
    rotate(canvasRef, angle, createWheel);
  }

  useLayoutEffect(() => {
    if (winnerInfo?.value) {
      spinToWinner();
    }
  }, [wheelCreated, winnerInfo?.value]);

  const onUpdate = (e) => {
    if (radius) {
      createWheel();
    }
    if (opts.length) {
      const angleInRadians = (e.rotate * pi) / 180;
      const index = (Math.ceil((angleInRadians / oneFullRotation) * opts.length) % opts.length);
      const winIndex = index === 0 ? 0 : (opts.length - index) % newColors.length;
      setWinnerInfo({
        id: opts[(opts.length - index) % opts?.length].id,
        value: opts[(opts.length - index) % opts?.length].body,
        color: newColors[winIndex]
      });
    }
  };

  useEffect(() => {
    if (radius) {
      createWheel();
    }
  }, [radius, fsWinnerId, opts?.length]);

  useEffect(() => {
    if (opts?.length === 0 || wheelState !== 'spin') return;
    getWinnerFromLot();
    initiateSpin();
  }, [wheelState, opts?.length]);

  return <>
    {radius
      && <div className={`flex ${layoutMode === 'square' ? 'flex-col-reverse justify-center' : 'gap-x-[7%]'} items-center w-full h-full`}>
        <div style={{ position: 'relative', width: `${canvasSide}px`, height: `${canvasSide}px` }} >
          <motion.canvas
            initial={{ rotate: 0 }}
            onUpdate={onUpdate}
            animate={animate}
            onAnimationComplete={onComplete}
            ref={canvasRef} width={canvasSide} height={canvasSide} />
          <img style={{ top: `${(radius - 22)}px` }} src='/images/wheel_marker.svg' className='absolute -right-26' />
          {isPreview ? <></> :
            <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 flex flex-col items-center pb-3'>
              {opts?.length === 0 &&
                <div className='w-[100%] mt-[140%] text-center'>
                  <p className='text-grey-900 font-semibold text-2xl'>The wheel is empty!</p>
                  <p className='text-grey-800 '>Participate in the chat to appear here</p>
                </div>}
              {noUser || isExploding || wheelState === 'spin' ? null : (
                <Button
                  size="md"
                  kind="custom"
                  disabled={isExploding || wheelState === 'spin'}
                  className={`mt-4 shadow-lg fadeIn w-10 h-20 rounded-full shadow-lg bg-white border-1
                border-grey-200 hover:bg-grey-200 font-semibold text-grey-900 aria-expanded:bg-grey-200 ${isExploding || wheelState === 'spin' ? 'bg-grey-200' : 'bg-white'}`}
                  onPress={() => {
                    isExploding || wheelState === 'spin' ? null :
                      setWheelState('spin');
                  }}
                >
                  Spin
                </Button>
              )}
            </div>
          }
        </div>
        {
          !splitDiv && <div className={`font-Poppins rounded-2xl relative px-4 py-3 text-center md:w-[55%] ${layoutMode === 'rectangle' ? 'lg:w-[45%] h-fit' : 'h-28 justify-center'} font-medium flex items-center`}>
            {winnerInfo?.value ?
              <>
                {currentRosFeature.type === 'newWheel' && wheelState !== 'spin' ?
                  <Image className="" src="/images/InteractionPage/crown.svg" />
                  : null}
                <p className={'ml-[16px] text-2xl font-semibold max-w-full truncate-text text-left'} style={{ color: textColor }}>{winnerInfo?.value}</p>
              </> : null
            }
            {opts?.length > 2 && winnerInfo?.value && wheelState !== 'spin' && !isPreview ?
              <div className='flex items-center'>
                {process.env.NEXT_PUBLIC_APP_ENV !== 'sandbox'
                  ? (
                    <div>
                      <HoverBox
                        position='top'
                        variant='white'
                        id='delete-icon'
                        handleSvgClick={removeParticipant}
                        toolTipDescription='Remove participant'
                        svg={<DeleteIcon sx={{ color: '#424242' }} />}
                        className='group flex items-center justify-center rounded-md w-9 h-9 bg-white shadow-md cursor-pointer ml-[16px]'
                      />
                    </div>) : null}
              </div> : null}

          </div>

        }
      </div>
    }
  </>;
};

export default WheelCanvas;
