import React, { useEffect, useRef, useState, useContext } from 'react';
import { StreamDataContext } from '@components/context/StreamContext';
import { AnimatedTile } from './talkingTiles/helper';
import ResultTile from './talkingTiles/ResultTile';
import {
  useSaveTilesState,
  useRestoreTilesState,
  useTalkingTilesSetup,
  useEngagementHandling,
  useTileManager,
  useFirestoreSubscription,
  useRemoveOldTilesState
} from './talkingTiles/hooks';

const TalkingTiles = ({
  streamId, currentRosResult, currentRosFeature, colorData, platformType,
  startInteraction, setCurrentFeatureId, interactionType, isImpromptu,
  setShowRosModel, moderationModeRef, activeInteractionRef, updateFSMeta,
  setShowNotification, streamSetting, setStartTime, showInstantCustomisationPopup,
  layoutMode, isSandbox, pinnedCommentRef
}) => {
  const featureSettings = { ...currentRosFeature, setting: { ...currentRosFeature.setting, layoutMode, isSandbox } };
  const {
    firestoreUnsub,
    setFirestoreUnsub,
    setFeatureId
  } = useContext(StreamDataContext);

  const { isRos } = interactionType;
  const containerRef = useRef(null);
  const [tiles, setTiles] = useState([]);

  useRemoveOldTilesState();
  useEffect(() => {
    saveTilesLocalStorage();
  }, [startInteraction,]);
  const {
    ttId,
    setttId,
    resultData,
    comments,
    setupTalkingTiles
  } = useTalkingTilesSetup(currentRosFeature, currentRosResult, setCurrentFeatureId, setFeatureId);

  const {
    managerRef,
    processedCommentsCount,
    initializeManager
  } = useTileManager(containerRef, featureSettings, startInteraction, setTiles, ttId);

  const { saveTilesLocalStorage } = useSaveTilesState(managerRef, ttId, startInteraction);

  const {
    isResultTileLoading,
    handleTalkingTiles,
    unsubscribeFromFirestore,
    stopTalkingTiles
  } = useEngagementHandling(
    streamId,
    platformType,
    currentRosFeature,
    setupTalkingTiles,
    updateFSMeta,
    firestoreUnsub,
    ttId,
    saveTilesLocalStorage,
    resultData
  );

  useFirestoreSubscription(
    ttId,
    isRos,
    streamId,
    setFirestoreUnsub,
    managerRef,
    processedCommentsCount,
    setShowNotification,
    comments,
    startInteraction
  );

  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handleTalkingTiles({ interactionId: currentRosResult[0].id });
    } else {
      handleTalkingTiles({ interactionId: null });
    }
    setStartTime(Date.now());
  };

  const manageTalkingTilesInteraction = () => {
    if (startInteraction && !moderationModeRef.current) {
      handleStartInteraction();
      pinnedCommentRef?.current?.close();
    } else if (ttId) {
      stopTalkingTiles();
      pinnedCommentRef?.current?.close();
    } else {
      if (currentRosResult && currentRosResult.length > 0) {
        handleTalkingTiles({ interactionId: currentRosResult[0].id });
      }
    }
  };

  useEffect(() => {
    if (!(ttId && isRos)) return;
    const controller = new AbortController();
    const signal = controller.signal;

    window.addEventListener('beforeunload', () => {
      saveTilesLocalStorage();
    }, { signal });

    return () => {
      controller.abort();
    };
  }, [ttId, startInteraction]);

  useEffect(() => {
    if (!currentRosFeature.id) return;

    setShowRosModel(false);
    manageTalkingTilesInteraction();
  }, [currentRosFeature.id, startInteraction]);

  const { restoreTilesState } = useRestoreTilesState(managerRef, ttId, startInteraction, currentRosResult);

  useEffect(() => {
    if (!containerRef.current) return;

    if (!managerRef.current && startInteraction) {
      initializeManager();
    }

    resultData.current = currentRosResult[0]?.comments || [];

    restoreTilesState();

    return () => {
      if (managerRef.current) {
        processedCommentsCount.current = 0;
      }
    };
  }, [currentRosFeature.id, containerRef.current, startInteraction]);

  useEffect(() => {
    if (!managerRef.current || !ttId) return;
    restoreTilesState();

    managerRef.current.setCurrentRosFeature(featureSettings);
    managerRef.current.updateExistingTiles();
  }, [currentRosFeature, ttId, managerRef.current]);

  const className = `h-full scrollbar-hide ${!startInteraction ? 'overflow-y-scroll ' : 'overflow-hidden  '}`;
  const showUsername = featureSettings?.setting?.showUserName;

  useEffect(() => {
    if (pinnedCommentRef?.current?.isOpen()) {
      const comment = pinnedCommentRef.current.getComment();
      pinnedCommentRef.current.showPinnedComment({ ...comment, showUsername });
    }
    return () => {
      pinnedCommentRef?.current?.close();
    };
  }, [showUsername]);

  return (
    <div
      className={className}
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      {!startInteraction ? (
        <ResultTile isResultTileLoading={isResultTileLoading} tiles={resultData.current} currentRosFeature={currentRosFeature} />
      ) : (
        <div
          className="flex flex-col items-center justify-center h-full w-full overflow-hidden relative  "
          ref={containerRef}
        >

          {tiles.map(tile => (
            <AnimatedTile
              onPress={() => {
                pinnedCommentRef?.current?.showPinnedComment({ username: tile.username, content: tile.words, showUsername });
              }}
              key={tile.id}
              tile={tile}
              tileGap={managerRef.current?.tileGap || 10}
              currentRosFeature={featureSettings}
            />
          ))}
        </div>
      )}

    </div>
  );
};

export default TalkingTiles;
